/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GitHub, Linkedin, Twitter } from "react-feather"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const { name, summary } = data.site.siteMetadata.author

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/profile-pic.jpeg"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
      />
      <div>
        <p>
          {summary}
          <strong>{name}</strong>
        </p>
        <p>
          <a
            target="_blank"
            className="icon"
            rel="noreferrer"
            href="https://github.com/aquibm"
          >
            <GitHub color="#FC8080" size={20} alt="@aquibn on GitHub" />
          </a>
          <a
            target="_blank"
            className="icon"
            rel="noreferrer"
            href="https://twitter.com/keeb_dev"
          >
            <Twitter color="#FC8080" size={20} alt="@keeb_dev on Twitter" />
          </a>
          <a
            target="_blank"
            className="icon"
            rel="noreferrer"
            href="https://www.linkedin.com/in/aquib-master-373b44b0/"
          >
            <Linkedin
              color="#FC8080"
              size={20}
              alt="Aquib Master on LinkedIn"
            />
          </a>
        </p>
      </div>
    </div>
  )
}

export default Bio
